/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-globals */
import React from 'react';
import './Slider.css';
import logo from './tw_logo.svg';
import {Slide} from '@material-ui/core/';
import { useHistory } from "react-router-dom";
import { returnStatement } from '@babel/types';

/*
   Sliders receive a few important properties:
   slide: A boolean determining whether the slider window is "alive" or not
   index: The (non-unique) index, from top to bottom starting at 0, of the tab in the hierarchy where it appears
   navColor: The color of the header bar at the top of the slider
   onClick: The function that should be called when the header bar is clicked
   name: The name of the sliding window
   content: The React element that should be inserted into the sliding window
   contentTitle: The title of the content inserted inside the window
*/
export default function Slider({slide, index, navColor, onClick, name, contentTitle, children, toggle}){
   var divStyle = { // Make sure each successive window covers all but the title of the last
      top: 3 * index + "em",
      height: `calc(100% - ${3 * index}em)` // Alternatively, use top: 5.5em and height: calc(100% - 5.5em) to make it work
   };
   
   var navStyle = {backgroundColor: navColor};
   var history = useHistory();
   return (
      <Slide direction="up" in={slide} mountOnEnter unmountOnExit>
         <div className='Slider' style={divStyle}>
            <nav index={index} style={navStyle} onClick={onClick}>
               {name}
               {index === 0 && <img src={logo} alt="Terrapin Works" onClick={() => history.push("/")}/>}
            </nav>
            <div className="SliderContentContainer">
               <div className="contentTitle">{contentTitle}</div>
               {React.Children.map(children, child => 
                  React.cloneElement(child,{
                     toggle:toggle
                  })   
               )}
            </div>
         </div>
      </Slide>
   );
}



