import React, { useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";
import pinpointLogo from "../assets/pinpoint_no_bg.svg";

import {
    Flex,
    Heading,
    Stack,
    Text,
    Image,
    useColorModeValue,
} from "@chakra-ui/react";

/* Page that indicates to the user that they have successfully signed out of Pinpoint and thanks them for using it. */
function ThankYouPage() {
    const history = useHistory(); // used to redirect to the next page

    /* Redirects thank you page to kiosk after 3 seconds */
    useEffect(() => {
        async function routeToKiosk() {
            await new Promise((delay) => setTimeout(delay, 3000));
            history.push("/kiosk");
        }
        routeToKiosk();
    }, []);

    return (
        <Flex
            minH={"100vh"}
            align={"center"}
            justify={"center"}
            bg={useColorModeValue("gray.50", "gray.800")}
        >
            <Stack spacing={8} direction='row' mx={"auto"} maxW={"100vh"} py={12} px={12}>
                <Stack align={"center"}>
                    <Image
                        alignSelf="auto"
                        width="6em"
                        src={pinpointLogo}
                        alt="pinpoint logo"
                        margin="2vh"
                    ></Image>
                    <Heading fontSize={"4xl"}>You have successfully signed out</Heading>
                    <Text fontSize={"lg"} color={"gray.600"}>
                        Thank you for using Pinpoint!
                    </Text>
                    <Text fontSize={"xs"} color={"grey.400"}>
                        This page will auto redirect in 3 seconds
                    </Text>
                </Stack>
            </Stack>
        </Flex>
    );
}

export default withRouter(ThankYouPage);