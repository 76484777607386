import React, { useState, useEffect } from "react";
import "./SliderMenu.css";
import {
   Alert, 
   AlertIcon, 
   AlertTitle, 
   AlertDescription, 
   Input
} from "@chakra-ui/react";

// This represents a list of different options to choose from, such as labs or machines
// permissionFunction and checkPermissionFunction are optional parameters used to gray out unpermitted options
export default function SliderMenu({ placeholderText, dataFunction, selected, toggle, onClick, permissionFunction, checkPermissionFunction, }) {
   const [filterText, setFilterText] = useState("");
   const [data, setData] = useState([]);
   const [permissions, setPermissions] = useState(null);
   const [serverCallFailed, setServerCallFailed] = useState(false);

   // Selectable and unselectable (grayed out) data items
   const [selectableData, setSelectableData] = useState([]);
   const [unselectableData, setUnselectableData] = useState([]);

   // User textbox that updates machine list accordingly
   const changeFilter = e => { setFilterText(e.target.value.toLowerCase()) };

   // TODO: Currently unsure of why these two useEffects() are here (and necessary). Since dataFunction, toggle, and permissionFunction
   // never change, there would be no reason for this useEffect() to run again which means there is a way to convert these state variables
   // into some const. Should look into this to make this more readable/efficient.
   useEffect(() => {
      dataFunction()
         .then(({items, status}) => {
            if (status === 200){
               setData(items);
            } else {
               setServerCallFailed(true);
            }
         })
         .catch(error => {
            setServerCallFailed(true);
         });

      if (permissionFunction !== undefined) {
         permissionFunction()
            .then(({ items, status }) => {
               if (status == 200) {
                  setPermissions(items);
               } else {
                  setServerCallFailed(true);
               }
            })
            .catch(error => {
               setServerCallFailed(true);
            });
   }}, [dataFunction, toggle, permissionFunction]);

   // Set selectable and unselectable data
   useEffect(() => {
      var selectable = [];
      var unselectable = [];
      if (checkPermissionFunction === undefined) {
         selectable = data;
      } else {
         data.map((e) => {
            if (checkPermissionFunction(permissions, e)) {
               selectable.push(e);
            } else {
               unselectable.push(e);
            }
         });
      }

      setSelectableData(selectable);
      setUnselectableData(unselectable);
   }, [data, permissions]);

   // Check if the server calls failed
   if(serverCallFailed) {
      // Return an alert component
      return (
         <div className="AlertContainer">
            <Alert
               status='error'
               variant='top-accent'
               flexDirection="column"
               alignItems="center"
               justifyConent="center"
               textAlign="center"
               height="200px"
               my="2vh"
            >
               <AlertIcon boxSize="40px" mr={0} />
               <AlertTitle mt={4} mb={1} fontSize="lg">Error</AlertTitle>
               <AlertDescription maxWidth="sm">
                  There was an error retrieving machine and permission data from the server.
               </AlertDescription>
            </Alert>
         </div>
      );
   } else {
      // Return a UI for the menu
      return (
         <div className="SliderMenu">
            <div className="SliderInput">
               <Input
                  value={filterText}
                  variant="filled"
                  size="lg"
                  placeholder={placeholderText}
                  onChange={changeFilter}
               />
            </div>
            <div className="SliderContent">
               <ul>{
                  // Items the user has permissions for
                  selectableData.filter(datum =>
                     datum.name.toLowerCase().includes(filterText)
                  ).map((e) => {
                     return (
                        <SliderMenuEntry
                           key={e.name}
                           selected={selected === e ? true : false}
                           onClick={() => onClick(e)}
                           name={e.name}
                           selectable={true}
                        />);
                  })}
                  {// Items the user doesn't have permissions for
                     unselectableData.filter(datum =>
                        datum.name.toLowerCase().includes(filterText)
                     ).map((e) => {
                        return (
                           <SliderMenuEntry
                              key={e.name}
                              selected={false}
                              onClick={null}
                              name={e.name}
                              selectable={false}
                           />);
                     })}
               </ul>
            </div>
         </div>
      );
   }
}

function SliderMenuEntry(props) {
   return (
      <li style={props.style}>
         <div
         onClick={props.onClick} 
         className={`SliderMenuEntry ${props.selected ? 'selected' : 'unselected'} ${props.selectable ? 'selectable' : 'unselectable'}`}
         >
            {props.name}
         </div>
      </li>
   );
}
