import React from "react";
import { useEffect, useMemo } from "react";
import "../utils/default.css";
import Backend from "../utils/api.js";
import useTabs from "../utils/useTabs.jsx";

import SliderDisplay from "./Components/SliderDisplay/SliderDisplay.jsx";
import SliderMenu from "./Components/SliderMenu/SliderMenu";
import Slider from "./Components/Slider/Slider";
import DataTable from "./Components/DataTable/DataTable";
import { withRouter, useLocation } from "react-router-dom";

function TopUsers() {
  const [tabState, pushTab, revert] = useTabs();
  let location = useLocation();

  const tabs = useMemo(
    () => [
      (
        newProps //0
      ) => (
        <Slider
          name="Top Machine Users"
          navColor="rgb(255, 238, 83)" //TODO change
        >
          <SliderMenu
            placeholderText="Select a machine..."
            dataFunction={() =>
              Backend.getMachineByLab(
                location.state.userId,
                location.state.labId
              )
            }
            onClick={(e) =>
              pushTab(
                tabs[1]({ ...newProps, labId: e.labId, machineName: e.name })
              )
            }
          />
        </Slider>
      ),
      (
        newProps //1
      ) => (
        <Slider name="Top Users" navColor="rgb(226, 150, 90)">
          <DataTable
            title="Top Machine Users"
            columns={[
              { title: "User Name", field: "topuser" },
              { title: "Lifetime Requests", field: "requestsmade" },
            ]}
            dataFunction={() =>
              getTopUsers(
                location.state.userId,
                newProps.labId,
                newProps.machineName
              )
            }
          ></DataTable>
        </Slider>
      ),
    ],
    [location.state.userId, pushTab, revert]
  );

  useEffect(
    () => pushTab(tabs[0]({ userId: location.state.userId })),
    [location.state.userId, tabs, pushTab]
  );

  return <SliderDisplay>{tabState.tabs}</SliderDisplay>;
}

function getTopUsers(UID, labId, machineName) {
  /* Map tracking user requests
   map = {user1_ID => #requests, user2_ID => #requests,...}
   */
  machineName = machineName.toLowerCase();
  let map = new Map();
  let promise = new Promise((resolve, reject) => {
    //Get all requests made for lab
    Backend.apiCall(`/labs/${labId}/requests`, `GET`, {
      UID: UID,
      id: labId,
    }).then(({ status, items }) => {
      if (status === 200) {
        //Parse data for each request. Store number of requests by user
        items.forEach((request) => {
          let requestMachineName = request.machine.toLowerCase();
          let userName = `${request.firstname}  ${request.lastname}`;

          if (requestMachineName == machineName) {
            if (map.has(userName)) {
              map.set(userName, map.get(userName) + 1);
            } else {
              map.set(userName, 1);
            }
          }
        });
      } else {
        reject(
          "Server Call Failed! Double check user has Admin privelages" +
            " and also that labId and machineId are valid."
        );
      }

      //Parse data from Map and store it in array specifically for the above DataTable
      let toReturn = Array.from(map, ([topuser, requestsmade]) => ({
        topuser,
        requestsmade,
      }));
      toReturn.sort((a, b) => (a.requestsmade < b.requestsmade ? 1 : -1));
      resolve({ items: toReturn, status: 200 });
    });
  });

  return promise;
}

export default withRouter(TopUsers);
