import React, { useState, useEffect } from "react";
import {
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Button,
} from "@material-ui/core";
import "./NewMachineDropDown.css";
import TextField from "@material-ui/core/TextField";
import SubmitButton from "./SubmitButton.jsx";
import DeleteButton from "./DeleteButton.jsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  dropDownMenu: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    overflow: "hidden",

    listStyle: "none",
    width: "100%",
  },

  centerDiv: {
    textAlign: "center",
    marginTop: "1em",
  },

  button: {
    textAlign: "center",
    width: " fit-content",
  },
});

export default function SliderFormDropDown({
  title,
  fields,
  dataFunctionMachineTypes,
  dataFunctionToolCategories,
  onSubmit,
  deleteForm = false,
}) {
  const classes = useStyles();
  const [machineType, setMachineType] = useState({ machineType: "None" });
  const [categoryID, setMachineToolCategory] = useState({ categoryID: "None"});
  const [machineName, setMachineName] = useState("");
  const [errors, setErrors] = useState({});
  const [choice, setChoice] = useState("");
  const [machineTypeData, setMachineTypeData] = useState([]);
  const [toolCategoryData, setToolCategoryData] = useState([]);

  useEffect(() => {
    dataFunctionMachineTypes().then(({ items, status }) => {
      if (status === 200) {
        setMachineTypeData(items);
      } else {
        alert("Unable to create a new machine at this time, can't retrieve Machine Types from server");
      }
    });

    dataFunctionToolCategories().then(({ items , status }) =>{
      if (status === 200) {
        setToolCategoryData(items);
      } else {
        alert("Unable to create a new machine at this time, can't retrieve tool categories from server")
      }
    });
  }, [dataFunctionMachineTypes, dataFunctionToolCategories]);

  const handleChange = (e, key) => {
    if (key == "machineType") {
      setMachineType({ ...machineType, [key]: e.target.value.name });
    } else if (key == "categoryID") {
      setMachineToolCategory({ ...categoryID, [key]: e.target.value.categoryID});
    } else if (key == "name") {
      setMachineName(e.target.value);
    }
  };

  const onChange = (e, key) => {
    handleChange(e, key);
    var result = fields.find((obj) => obj.key === key);

    if (result.validate !== "" && !e.target.value.match(result.validate)) {
      setErrors({ ...errors, [key]: result.errorText });
    } else {
      setErrors({ ...errors, [key]: "" });
    }
  };

  // Populate the submission object with default values (if they are available)
  const initializeSubmission = () => {
    if (Object.keys(machineType).length === 0) {
      let tempSubmission = machineType;
      fields.map((f) => {
        if (f.defaultValue !== undefined) {
          tempSubmission = {
            ...tempSubmission,
            [f.key]: f.defaultValue,
          };
        }
      });
      if (Object.keys(tempSubmission).length !== 0) {
        setMachineType(tempSubmission);
      }
    }

    return null;
  };
  let button;
  if (deleteForm) {
    button = (
      <DeleteButton
        submission={machineType}
        errors={errors}
        fields={fields}
        onSubmit={onSubmit}
      />
    );
  } else {
    button = (
      <SubmitButton
        submission={
          { machineType: machineType.machineType,
            categoryID: categoryID.categoryID,
            name: machineName
          }
        }
        errors={errors}
        fields={fields}
        onSubmit={onSubmit}
      />
    );
  }
  return (
    <form className="SliderFormDropDown">
      {fields.map((f) => (
        <TextField
          required
          margin="dense"
          key={f.key}
          id={f.key}
          label={f.placeholder}
          helperText={errors[f.key]}
          error={errors[f.key] ? true : false}
          onChange={(e) => onChange(e, f.key)}
          value={f.value}
          defaultValue={f.defaultValue}
          disabled={f.disabled}
        />
      ))}
      {/******  Drop Down Menu ******/}
      <FormControl required className={classes.dropDownMenu}>
        <InputLabel htmlFor="machineType">{title}</InputLabel>
        <Select
          inputProps={{
            id: "machineType",
          }}
          value={console.log(machineType)}
          onChange={(e) => handleChange(e, "machineType")}
        >
          {machineTypeData.map((f) => (
            <MenuItem value={f}>{f["name"]}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl required className={classes.dropDownMenu}>
        <InputLabel htmlFor="categoryName">{"Tool Category"}</InputLabel>
        <Select
          inputProps={{
            id: "categoryName",
          }}
          onChange={(e) => handleChange(e, "categoryID")}
        >
          {toolCategoryData.map((f) => (
            <MenuItem value={f}>{f["categoryName"]}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {initializeSubmission()}
      <div className="centerDiv">{button}</div>
    </form>
  );
}
