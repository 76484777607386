import React, { useState } from 'react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import "./OptionList.css";
import Button from '@material-ui/core/Button';


function OptionList({elements, name }) {
  const [switchStates, setSwitchStates] = useState(
    elements.map((element) => element.default)
  );
  const [confirmations, setConfirmations] = useState(
    elements.map((element) => "")
  );


  const handleSwitchChange = async (index, checked) => {
    const newSwitchStates = [...switchStates];
    newSwitchStates[index] = checked;
    setSwitchStates(newSwitchStates);

    let newConfirmations = [...confirmations];
    newConfirmations[index] = <span className="loadingAnimated">{"Loading..."}</span>;
    setConfirmations(newConfirmations);


    newConfirmations = [...confirmations];
    newConfirmations[index] = await elements[index].onClick(checked);
    setConfirmations(newConfirmations);
  };

  return (
    <div className="OptionList">
      {name ? <h1 className='header'>{name}</h1> : ""}
      {elements.filter(element => element.visible).map((element, index) => (
        <div className="element">
            {element.type === "switch" ? (
            <FormControlLabel
            key={index}
            control={
                <Switch
                checked={switchStates[index]} 
                onChange={(event) => handleSwitchChange(index, event.target.checked)}
                name={element.name}
                color="primary"
                /> 
            }
            label={element.name}
            />)
            : (
            <Button
              variant="contained"
              color="primary"
              onClick={element.onClick}
            >
              {element.name}
            </Button>
          )}
            <div className="confirmation">
                {confirmations[index]}
            </div>
        </div>
      ))}
    </div>
  );
}

export default OptionList;
