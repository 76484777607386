import React, { useState, useEffect } from "react";
import Backend from "../../../utils/api.js";
import { 
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Spinner
} from "@chakra-ui/react";

/**
 * Wrapper for the Chakra Alert component. 
 * This was designed to get around the limitations of asynchronous functions in React 
 * (and the limits in `Admin.jsx`). 
 * The function makes a call to the backend and Badgr API, and if the UID doesn't have a valid Badgr 
 * access token, an error response will be returned. 
 * If the Badgr API fails, an `Alert` will be displayed.
 * 
 * @author [Cedric Prentice](https://code.umd.edu/cprentic)
 * @param uid The UID of the user
 * @returns Container that is either empty (if the call completes successfully) 
 * or has a Chakra `Alert` component (if the access token is invalid)
 */
export default function AsyncAlert({uid}) {
    // States for the call status and potential alert status
    const [callFailed, setCallFailed] = useState(false);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    // This is how we interact with the Badgr API
    useEffect(() => {
        async function getAlertState(UID) {
            // Call the backend (which calls the Badgr API)
            setLoading(true);
            const response = await Backend.getFullBadgeInfo(UID);
            
            // Parse the response
            let states = {};
            if(response.hasOwnProperty("alert")) {
                states = {failed: true, message: response.alert};
            } else {
                states = {failed: false, message: ""};
            }
            
            // Update the states
            setLoading(false);
            setCallFailed(states.failed);
            setMessage(states.message);
        }
    
        // Call the useEffect hook
        getAlertState(uid);
    }, [uid]);
    
    // See if the API call is complete and if the call failed
    if(loading === true) {
        // Return a spinner while the API call runs
        return (
            <div className="async-alert-container">
                <Spinner /> <text> Loading Table Data...</text>
            </div>
        );
    } else if(callFailed === true) {
        // Return an Alert component
        return (
            <div className="async-alert-container">
                <Alert status='error' variant='left-accent' my="2vh">
                    <AlertIcon />
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription>{message}</AlertDescription>
                </Alert>
            </div>
        );
    } else {
        // Return nothing (loading is complete and correct at this point)
        return (
            <div className="async-alert-container"></div>
        );
    }
}