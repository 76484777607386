import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';

export default function DataTable(
   {submitRowChange, 
      getUser, 
      submitNewRow, 
      submitRowDeletion,
      columns, 
      dataFunction, 
      title,
      errorMsg = "Action failed, double check the data you entered is valid."
   }) {
   const [data, setData] = useState([]);

   useEffect(() =>{
      dataFunction()
     .then(({items, status}) => {

         if (status === 200){
            
            setData(items);
         } else {
            alert("server call failed!");
         }
      });
   }, [dataFunction]);

   if (submitRowChange != null && submitNewRow != null){
      return (
         <MaterialTable
            title={title}
            columns={columns}
            data={data}
            editable={{
               onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                  setTimeout(() => {
                     submitRowChange(newData, oldData);
                     const dataUpdate = [...data];
                     const index = oldData.tableData.id;
                     dataUpdate[index] = newData;
                     setData([...dataUpdate]);
                     resolve()
                  }, 1000)
               }),
               onRowAdd: newData =>
                  new Promise((resolve, reject) => {
                  setTimeout(() => {
                     submitNewRow(newData).then((res) =>{
                        if (res.status >= 400) {
                           window.alert(errorMsg);
                        } else {
                           //Update the data in DataTable
                           setData([...data, newData]);
                        }
                     });
                     resolve()
                  }, 1000)
               })
            }}
         />
      );
   } else if (submitRowDeletion != null && submitNewRow != null) {
      return (
         <MaterialTable
            title={title}
            columns={columns}
            data={data}
            editable={{
               onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                  setTimeout(() => {
                     submitRowDeletion(oldData);
                     const dataDelete = [...data];
                     const index = oldData.tableData.id;
                     dataDelete.splice(index, 1);
                     setData([...dataDelete]);
                     resolve();
                  }, 1000)
               }),
               onRowAdd: newData =>
                  new Promise((resolve, reject) => {
                  setTimeout(() => {
                     submitNewRow(newData).then((res) =>{
                        if (res.status >= 400) {
                           window.alert(errorMsg);
                        } else {
                           dataFunction().then(({items, status}) => {

                              if (status === 200){
                                 
                                 setData(items);
                              } else {
                                 alert("server call failed!");
                              }
                           });
                        }
                     });
                     resolve();
                  }, 1000)
               })
            }}
         />
      );
   } else {
      return (
         <MaterialTable
            title={title}
            columns={columns}
            data={data}
         />
      );
   }
}
