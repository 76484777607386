import React, { useState, useEffect } from "react";
import Backend from "../utils/api.js";
import { AbsoluteCenter, Box, Flex, Text, Spinner } from "@chakra-ui/react";

export default function BadgrCallback({ user }) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorEcountered, setErrorEncountered] = useState(false);

  useEffect(() => {
    if (window.location.hostname === 'localhost') {
      window.location.hostname = 'localtest.dev.umd.edu';
    } else {
      let code = extractBadgrCode();
      console.log("code: ");
      console.log(code);
      setIsLoading(true);    
      postCanvasBadgeRequest(code).then(setIsLoading(false));
    }
  }, []);

  function extractBadgrCode() {
    return new URL(window.location.toString()).searchParams.get("code");
  }

  async function postCanvasBadgeRequest(code) {
    Backend.badgrOAuth(code).then((res) => {
      if (res.status === 200) {
        setIsLoading(true);
        console.log(`AT: ${res.access_token}`);

        Backend.editUser({
          UID: user.UID,
          args: { 
            'badgrAccessToken': res.access_token,
            'badgrRefreshToken': res.refresh_token,
          }},
        ).then((res) => {
          if (res.status === 201) {
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setErrorEncountered(true);
          } 
        });

      } else {
        setIsLoading(false);
        setErrorEncountered(true);
      }
    });
  }

    if (isLoading) {
      return (
        <AbsoluteCenter>
        <Box position="relative">
          <Text as="h2" fontSize="lg" fontWeight={"bold"} mb={4} align="center">
            Loading...
          </Text>
          <Spinner />
        </Box>
        </AbsoluteCenter>
      )
    } else if (errorEcountered) {
      return (
        <AbsoluteCenter>
        <Box position="relative">
          <Text as="h2" fontSize="75" fontWeight={"bold"} mb={4} align="center">
            &#128679;
          </Text>
          <Text as="h2" fontSize="lg" fontWeight={"bold"} mb={4} align="center">
            We encountered an error while trying to connect Canvas Badges.
          </Text>
          <Text align="center">
            Please try again.
          </Text>
        </Box>
        </AbsoluteCenter>
      )
    } else {
      return (
        <AbsoluteCenter>
        <Box position="relative">
          <Text as="h2" fontSize="75" fontWeight={"bold"} mb={4} align="center">
            &#127881;
          </Text>
          <Text as="h2" fontSize="lg" fontWeight={"bold"} mb={4} align="center">
            Canvas Badges connected successfully.
          </Text>
          <Text align="center">
            You can close this page.
          </Text>
        </Box>
        </AbsoluteCenter>
      )
    }
}
