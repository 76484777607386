//api hook prototype
const apiHook = {
  setURL(URL) {
    this.baseURL = URL;
  },

  /// Send an API call to the backend
  /// - path: The directory to append to the base URL
  /// - method: The requesting method- explicitly GET or POST
  /// - params: The keys and values to attach to this request
  /// - accept: Which response type is expected / accepted, set to JSON by default
  apiCall(path, method, params, accept = "application/json") {
    // Construct a full URL object from the concatenation of the two URL strings
    var fullURL = new URL(this.baseURL + path);
    // Represent the provided keys and values in an agreeable format
    fullURL.search = new URLSearchParams(params).toString();
    // Return the result of this fetch
    return (
      fetch(fullURL, {
        method: method,
        headers: {
          Accept: accept,
          "Access-Control-Allow-Origin": "*",
        },
      })
        // Once a response is returned
        .then((response) => {
          // If the response type was in CSV format
          if (accept === "text/csv") {
            return response.blob().then((blob) => {
              let content = response.headers.get("content-disposition");
              let filename = content.substring(
                content.indexOf("filename=") + 9,
                content.indexOf(".csv")
              );
              const element = document.createElement("a");
              element.href = URL.createObjectURL(blob);
              element.download = filename || "download.csv";
              document.body.appendChild(element); // Required for this to work in FireFox
              element.click();
            });
          }
          // If the response type was in JSON format
          else {
            return (
              response.status === 204 ? Promise.resolve({ status: response.status }) :
              // Convert the response to an Object
              response
                .json()
                // When the conversion completes
                .then((responseJSON) =>
                  // Return the status and JSON data as an object
                  ({ status: response.status, ...responseJSON.data })
                )
            );
          }
        })
    );
  },

  /// Download all pending requests
  /// - UID: The University ID of the User viewing the requests
  downloadAllRequests(UID) {
    return this.apiCall("/requests/", "GET", { UID: UID }, "text/csv");
  },

  /// Download all pending requests in a given Lab
  /// - UID: The University ID of the User viewing the requests
  /// - labID: The unique identifier of the Lab in question
  downloadLabRequests(UID, labID) {
    return this.apiCall(
      `/labs/${labID}/requests`,
      "GET",
      { UID: UID },
      "text/csv"
    );
  },

  /// Download all pending requests for a given Machine
  /// - UID: The University ID of the User viewing the requests
  /// - machineID: The unique identifier of the Machine in question
  downloadMachineRequests(UID, machineID) {
    return this.apiCall(
      `/machines/${machineID}/requests`,
      "GET",
      { UID: UID },
      "text/csv"
    );
  },

  /* REQUESTS */

  /// Creates a new Request for a given machine
  /// - UID: The University ID associated with this User
  /// - machineId: The identifier for the requested machine
  /// - reason: User's reason for requesting this machine permission
  /*
  newRequest has a different setup from the other frontend API calls. 
  The SwaggerUI passes a list of items into the request like this: 
  "machine=1&machine=2&...". But, when you pass in the list of machines from 
  the frontend, it gets inserted in the URL like this: "machines=1,2,...". 
  This doesn't work, so the current apiCall method cannot be used for this 
  request/endpoint. In this method, I baseically remade parts of apiCall in 
  newRequest to get around this. First, I reformat the list of machine IDs 
  into the format that the SwaggerUI uses for the request URL. The rest of the 
  method is basically lifted from apiCall but without the parameter stuff. By 
  doing this, the list of machines is correctly placed into the request URL 
  and the frontend works as expected.
   */
  newRequest({ UID, machineIds, reason, lab }) {
    //Make the machineIds list into a usable string for the request
    var machinesString = "";
    for (var i = 0; i < machineIds.length; i++) {
      machinesString = machinesString + "machines=" + machineIds[i];
      if (i < machineIds.length - 1) {
        machinesString = machinesString + "&";
      }
    }

    //The bunk way to do this is to recreate apiCall so I can pass in the URL design I want
    var fullURL = new URL(this.baseURL + `/requests/?UID=${UID}&${machinesString}&reason=${reason}&lab=${lab}`);
    return (
      fetch(fullURL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then((response) => {
          return (
            // Convert the response to an Object
            response
              .json()
              // When the conversion completes
              .then((responseJSON) =>
                // Return the status and JSON data as an object
                ({ status: response.status, ...responseJSON.data })
              )
          );
        })
    );
    /*return this.apiCall("/requests/", "POST", {
      UID: UID,
      machines: machineIds,
      reason: reason,
    });*/
  },

  /* USERS */

  /// Obtains an array of Users
  /// - UID: The University ID associated with this User
  /// - num: The maximum number of Users to fetch
  getUsers(UID, num) {
    // TODO: Add the ability to specifify page number to allow frontend pagination
    return this.apiCall("/users/", "GET", { UID: UID, per_page: num });
  },

  /// Obtains a singular user
  /// - UID: The University ID associated with this User
  getUser(UID) {
    /* 
         This is only functional for Users requesting their own document.
         In the future we may want to allow for the requesting of a different resource 
         than the User's own document. Lab Managers may want to see the details of a single
         User in much the same way as in getUsers.
      */
    return this.apiCall(`/users/${UID}`, "GET", { UID: UID });
  },

  /// Takes a swipenum, calls backend to convert swipeNum to UID. Backend calls
  /// University api to convert swipenum to uid.
  /// - swipeNum: swipeNumber of user
  convertSwipeNumToUID(swipeNum) {
    return this.apiCall(`/users/swipenum_convert/${swipeNum}`, "GET");
  },

  // Get basic student information (name, email, etc.)
  // UID: UID of the current user
  getUserInfoFromUID(UID) {
    return this.apiCall(`/users/uid_convert/${UID}`, "GET");
  },

  /// Creates a new User in the database
  /// - UID: The University ID associated with this User
  /// - directory
  /// - directoryID: Directory ID
  /// - firstName: User's first name
  /// - lastName: User's last name
  /// - email: User's email
  /// - isSuperAdmin: True if user is a super admin, false otherwise
  /// - The swipe number associated with this user- REDUNDANT
  newUser({
    UID,
    args,
  }) {
    delete args["status"];
    args["isSuperAdmin"] || (args["isSuperAdmin"] = false);
    return this.apiCall(`/users/${UID}`, 'PUT', { UID: UID, ...args });
  },

  /// Edits the information associated with a User
  /// Fields are identical to newUser except that isSuperAdmin has no default value
  editUser({
    UID,
    args
  }) {
    delete args['status'];
    return this.apiCall(`/users/${UID}`, "PATCH", {
      UID: UID,
      ...args
    });
  },

  /// Retrieves all lab permissions associated with a User
  /// - UID: The University ID of the User in question
  getLabPermissionsByUser(UID) {
    return this.apiCall(`/users/${UID}/lab_permissions`, "GET", { UID: UID });
  },

  /// Retrieves all pending permission requests associated with a User
  /// - UID: The University ID of the User in question
  getRequestsByUser(UID) {
    return this.apiCall(`/users/${UID}/requests`, "GET", { UID: UID });
  },

  /// Retrieves all MachineTypes a user has permission to. Based in real time on
  /// the Badges a user has been awarded
  /// - UID: The University ID of the User to query permissions of
  getMachineTypesByUser(UID) {
    return this.apiCall(`/users/${UID}/tool_permissions`, "GET");
  },

  /// Checks whether the user has authenticated with Canvas Badges before
  /// - UID: The University ID of the user to check Badgr integration for
  checkBadgrAuthentication(UID) {
    return this.apiCall(`/users/${UID}/auth_check`, "GET");
  },

  /// Retrieves pending permission requests in a given lab for a given User
  /// - UID: The University ID of the User viewing the requests
  /// - Lab: The unique identifier of the Lab in question
  getRequestsByLab(UID, Lab) {
    return this.apiCall(
      `/labs/${Lab}/requests`,
      "GET",
      UID ? { UID: UID } : {}
    );
  },

  /// Retrieves all the machines a User is actively using in a given Lab
  /// - UID: The University ID of the User using the machines
  /// - lab: The unique identifier of the Lab that the machines are being used from
  getActiveMachinesByUserForLab(UID, lab) {
    return this.apiCall(`/users/${UID}/active_machines`, "GET", {
      UID: UID,
      lab: lab,
    });
  },

  /* LABS */

  /// Retrieve an array of Labs
  /// - num: The quantity of lab objects to retrieve
  getLabs(num) {
    // TODO: Add page number to allow for frontend pagination
    return this.apiCall("/labs/", "GET", { per_page: num });
  },

  /// Retrieve a singular Lab object
  /// - id: The unique identifier associated with this Lab
  getLabById({ id, UID }) {
    return this.apiCall(`/labs/${id}`, "GET", { UID: UID });
  },

  /// Creates a new Lab
  /// - UID: The University ID of the User trying to create the Lab
  /// - id: The unique identifier to assign to the new Lab
  /// - name: THe name of the new Lab
  newLab({ UID, id, name }) {
    return this.apiCall(`/labs/${id}`, "PUT", { badgrIntegration: false, labName: name, UID: UID });
  },

  /// Edits a Lab's information
  /// - id: The unique identifier by which to query the Lab
  /// - name: The new name for the Lab
  editLab(id, name) {
    return this.apiCall(`/labs/${id}`, "PUT", { labName: name });
  },

  //Edits a Lab's Badgr Integration Status
  // - id: The unique identifier by which to query the lab
  // - UID: The University ID of the User trying to edit the lab
  // - badgrIntegration: The new status of the lab's badgr integration
  editLabBadgrIntegration(id, UID, badgrIntegration) {
    return this.apiCall(`/labs/${id}`, "PATCH", { UID: UID, badgrIntegration: badgrIntegration });
  },

  /// Toggle the availability of a Lab
  /// - id: The unique identifier of the Lab in question
  /// - disable: Whether to disable this Lab (true) or enable it (false)
  toggleLab({ id, UID, disable }) {
    return this.getLabById({ id: id, UID: UID }).then((res) => {
      return this.apiCall(`/labs/${id}`, "PATCH", {
        UID: UID,
        labName: res.name,
        badgrIntegration: res.badgrIntegration,
        deleted: disable,
      });
    });
  },

  /// Retrieves Machines stored in a given Lab
  /// - UID: The University ID of the User viewing the Machines
  /// - id: The unique identifier of the Lab in question
  getMachineByLab(UID, id) {
    return this.apiCall(`/labs/${id}/machines`, "GET", { UID: UID, per_page: 500 });
  },

  /// Retrieves Permissions associated with a given Lab
  /// - UID: The University ID of the User viewing the permissions
  /// - id: The unique identifier of the Lab in question
  getPermissionsByLab(UID, id) {
    return this.apiCall(`/labs/${id}/permissions`, "GET", { UID: UID });
  },

  /// Retrieves all users with permission for this lab
  /// - UID: The University ID of the User viewing the permissions
  /// - id: The unique identifier of the Lab in question
  getUsersByLab(UID, id) {
    const permissions_query = this.getPermissionsByLab(UID, id);
    const users_query = this.apiCall(`/labs/${id}/users`, "GET", { UID: UID });
    return Promise.all([permissions_query, users_query])
      .then( ([permissions, users]) => {
        users.items = users.items.map(item => {
          return { ...item, user: item.UID, ...permissions.items.find( usr => {
            return usr.user == item.UID;
          }) };
        });
        return users;
      })
  },

  /// Creates new lab permission
  /// - UID: The University ID of the User viewing the permissions
  /// - user: id for the user
  /// - lab: id for the lab
  newLabPermission(UID, user, lab) {
    return this.apiCall(`/lab_permissions/`, "POST", {
      UID: UID,
      user: user,
      lab: lab,
    });
  },

  /// Retrieves an array of active users in a given Lab
  /// - num: The quantity of active users to retrieve
  /// - id: The unique identifier of the Lab in question
  getActiveUsersByLab(id, num) {
    return this.apiCall(`/labs/${id}/active_users`, "GET", { per_page: num });
  },

  /* MACHINES */

  /// Retrieves an individual Machine
  /// - id: The unique identifier of the Machine in question
  getMachineById(UID, id) {
    return this.apiCall(`/machines/${id}`, "GET", { UID: UID });
  },

  /// Edits the information associated with a Machine. The following keys
  /// can be passed in args (with their respective values) to update a machine
  /// - id: The unique identifier of the Machine in question
  /// - machineType: The new name of the Machine
  /// - categoryID: New category of machine
  /// - restricted: New restriction of machine
  /// - lab: New lab of machine
  /// - sublabID: New sublab of machine
  /// - deleted: Update deletion state
  /// - quizID: New quizID of machine
  /// - machineStatus: New Status of machine
  editMachine(UID, id, args) {
    return this.apiCall("/machines/" + id, "PATCH", {
      UID: UID,
      ...args,
    });
  },

  /// Toggle the availability of a Machine
  /// - id: The unique identifier of the Machine in question
  /// - disable: Whether to disable this Machine (true) or enable it (false)
  toggleMachine(id, disable) {
    console.log("Setting machine to ", disable);
    return this.getMachineById(id, (res) => {
      // TODO: Change the API call to use PATCH when it is available
      //return this.apiCall("/machines/" + id, 'PUT', {...res, deleted: disable});
      return this.apiCall("/machines/" + id, "PUT", {
        machineType: res.data.name,
        category: "Test category",
        restricted: res.data.restricted,
        lab: res.data.labdId,
        deleted: disable,
      });
    });
  },

  /// Deletes the lab permission
  /// - UID: The University ID of the User viewing the permissions
  /// - id: identifier of the lab permission object
  deleteLabPermission(UID, id) {
    return this.apiCall(`/lab_permissions/${id}`, "DELETE", {
      UID: UID
    }).then(response => {
      alert(JSON.stringify(response, null, 4));
      return response;
    });
  },

  /* MACHINES */

  /// Retrieves an individual Machine
  /// - id: The unique identifier of the Machine in question
  getMachineById(UID, id) {
    return this.apiCall(`/machines/${id}`, "GET", { UID: UID });
  },

  /// Creates a new Machine
  /// - UID: The University ID of the User creating the Machine
  /// - name: The name of the Machine
  /// - category: The type of the Machine
  /// - lab: The unique identifier of the Machine's Lab
  newMachine({ UID, name, machineType, lab, categoryID }) {
    return this.apiCall("/machines/", "POST", {
      UID: UID,
      machineName: name,
      machineType: machineType,
      categoryID: categoryID,
      lab: lab,
    });
  },

  /// Toggle the availability of a Machine
  /// - id: The unique identifier of the Machine in question
  /// - disable: Whether to disable this Machine (true) or enable it (false)
  toggleMachine(id, disable) {
    console.log("Setting machine to ", disable);
    return this.getMachineById(id, (res) => {
      // TODO: Change the API call to use PATCH when it is available
      //return this.apiCall("/machines/" + id, 'PUT', {...res, deleted: disable});
      return this.apiCall("/machines/" + id, "PUT", {
        machineType: res.data.name,
        category: "Test category",
        restricted: res.data.restricted,
        lab: res.data.labdId,
        deleted: disable,
      });
    });
  },

  /// Disable a Machine
  /// - id: The unique identifier of the Machine in question
  disableMachine(id) {
    return this.toggleMachine(id, true);
  },

  /// Enable a Machine
  /// - id: The unique identifier of the Machine in question
  enableMachine(id) {
    return this.toggleMachine(id, false);
  },

  /* MACHINE TYPES */

  /// Retrieve an array of Machine Types
  /// - num: The quantity of Machine Type objects to retrieve
  getMachineTypes(UID, num) {
    // TODO: Add page number to allow for frontend pagination
    return this.apiCall("/master_machineTypes/", "GET", {
      UID: UID,
      per_page: num,
    });
  },

  /// Creates a new Machine Type
  /// - ID: The ID of the Machine Type
  /// - MachineType: The name of the Machine Type
  newMachineType(UID, machine_TypeID, machineType) {
    return this.apiCall("/master_machineTypes/", "POST", {
      UID: UID,
      machine_TypeID: machine_TypeID,
      machineType: machineType,
    });
  },

  /*
   Updates the Machine Type
   machineType_ID: The ID of the Machine Type being edited
   machineType: new machineType
   */
  updateMachineType(UID, id, machineType) {
    return this.apiCall(`/master_machineTypes/${id}`, "PATCH", {
      UID: UID,
      machineType: machineType,
    });
  },

  /// Swipe a user in
  /// - userUID: UID of user swiping in
  /// - userSwipeNum: swipenum of userswiping in
  /// - lab: labID of lab user is swiping into
  userSwipeIn({ userUID, userSwipeNum, lab }) {
    return this.apiCall("/swipes/", "POST", {
      user: userUID,
      swipeNum: userSwipeNum,
      labID: lab,
    });
  },

  /// Swipe the last outstanding swipe for a user out (if any exist)
  /// - id: UID of user to attempt swipe out.
  userSwipeOut(id, lab) {
    return this.apiCall(`/swipes/${id}`, "PATCH", { lab: lab });
  },

  /// Check if a user is swiped in
  /// - id: UID of user to check
  ///
  /// TODO WARNING: This is an extreme edge case but if a user has more than per_page sessions in their lifetime in the
  /// system then the frontend will fail to ask them if they'd like to continue their current session or sign out.
  async isUserSwipedIn(id, lab) {
    let res = await this.apiCall(`/swipes/${id}`, "GET", {
      page: 1,
      per_page: 999999999,
    });
    // Filter down to just swipes for this lab
    let items = res.items.filter((item) => item.labID == lab);

    if (items.length === 0) {
      // if there are no swipes, user is not swiped in
      return false;
    }
    return items[items.length - 1].swipe_out_time === null; // get last swipe and check if it is swiped out
  },

  /// Get the swipe-in time for an active user
  /// - id: UID of user to check
  /// - lab: Lab user is active in
  async getUserSwipeInTime(id, lab) {
    // Get the swipes for the current user
    let res = await this.apiCall(`/swipes/${id}`, "GET", {
      page: 1,
      per_page: 20,
    });

    // Filter down to just swipes for this lab
    let items = res.items.filter((item) => item.labID == lab);
    
    // Check if the user is swiped into the current lab
    if ((items.length === 0) || (items[items.length - 1].swipe_out_time !== null)) {
      // Return the current time - this case shouldn't be hit
      return new Date();
    } else {
      // Return the swipe-in time for the user
      return new Date(items[items.length - 1].swipe_in_time);
    }
  },

  /// Check whether the last swipe out for a user was automatic or manual
  /// - id: UID of user to check
  async isLastSwipeOutAuto(id) {
    // Get the swipes for the current user
    let swipes_res = await this.apiCall(`/swipes/${id}`, "GET", {
      page: 1,
      per_page: 999999999,
    });

    // Get all of the labs
    let labs_res = await this.apiCall("/labs/", "GET", { per_page: 1000 });
    let labs = labs_res.items;

    // Loop through the labs (open session check)
    for (let i = 0; i < labs.length; i++) {
      // Get the swipes for the current lab
      let items = swipes_res.items.filter((item) => item.labID == labs[i].id);

      // If the user has an active session in any lab, return false
      if ((items.length > 0) && (items[items.length - 1].swipe_out_time === null)) {
        return false
      }
    }

    // Get the most recent swipe (for any lab)
    let items = swipes_res.items;

    /*
      Conditions for true being returned:
      1. There's at least one swipe
      2. The user isn't currently swiped into any labs
      3. The most recent swipe out was automatic
     */
    return (items.length > 0) && 
      (items[items.length - 1].swipe_out_time !== null) && 
      (items[items.length - 1].auto_swiped_out === true);
  },

  /// Retrieves an array of Tool Categories for a given Lab
  /// - lab: The unique identifier of the Lab the User is signed into
  /// - num: The quantity of Tool Category objects to retrieve
  getToolCategoriesByLab(UID, lab, num) {
    return this.apiCall(`/tool_categories/${lab}`, "GET", {
      UID: UID,
      lab: lab,
      per_page: num,
    });
  },

  /// Creates a new Tool Category
  /// - UID: The University ID of the User creating the Tool Category
  /// - categoryName: The name of the new Tool Category
  /// - labID: The lab for the new Tool Category
  newToolCategory(UID, categoryName, lab) {
    return this.apiCall("/tool_categories/", "POST", {
      UID: UID,
      categoryName: categoryName,
      labID: lab,
    });
  },

  ///Updates an existing Tool Category
  /// - UID: The University ID of the User creating the Tool Category
  /// - categoryName: The name of the updated Tool Category
  /// - labID: The lab for the updated Tool Category
  updateToolCategory(UID, categoryID, categoryName, lab) {
    return this.apiCall(`/tool_categories/${categoryID}`, "PATCH", {
      UID: UID,
      categoryName: categoryName,
      labID: lab,
    });
  },

  /* BADGE-MACHINE-TYPE RELATIONSHIPS */

  ///Get all badges and their information
  /// - UID: The University ID of the User retrieving the entity-machine-type relationships
  async getFullBadgeInfo(UID) {
    // Get the badges from the backend
    var badges = await this.apiCall("/badges/", "GET");

    // Loop through the badges
    for (var i in badges.items) {
      // Save the badge data as a temporary variable
      var tempItem = badges.items[i];

      // Attempt to get the badge's information from the backend
      try {
        var badgeInfo = await this.apiCall(`/badgr_requests/badge_class_info/${tempItem.badgeclass_entity_id}`, "GET", { UID: UID });
      } catch (e) {
        // A 500 error in the Badgr API can throw a type error
        if (e instanceof TypeError) {
          var badgeInfo = { status: 500 };
        } else {
          // If it isn't the error we're watching for, throw it
          throw e;
        }
      }

      if (badgeInfo.status === 200) {
        // Add the new fields to the JSON
        badges.items[i].badge_name = badgeInfo.name;
        badges.items[i].description = badgeInfo.description;
        badges.items[i].creator = badgeInfo.createdBy;
      } else {
        badges.alert = "The Badgr API failed to authenticate your access token. Please verify that you have a valid access token.";
      }
    }

    // Return the result
    if (badges.hasOwnProperty("alert")) {
      badges.items = [];
    }
    return badges;
  },

  /// Creates a new Badge-Machine-Type relationship
  /// - UID: The University ID of the User creating a entity-machine-type relationship
  /// - newEntityId: Entity ID of the new relationship
  /// - newMachineType: Master machine type of the new relationship
  /// - lab: id for the lab
  async newBadgeMachineTypeRelationship(UID, newEntityId, newMachineType, lab) {
    //See if the entityId is valid
    var badgeInfo = await this.apiCall(`/badgr_requests/badge_class_info/${newEntityId}`, "GET", { UID: UID })

    //Throw an error if the call to the Badgr API fails
    if (badgeInfo.status !== 200) {
      return { status: 400, error: "Not an existing Badge ID" }
    } else {
      return await this.apiCall("/badges/", "POST", {
        UID: UID,
        badgeclass_entity_id: newEntityId,
        machineType: newMachineType,
        lab: lab
      });
    }
  },

  /// Creates a new Badge-Machine-Type relationship
  /// - UID: The University ID of the User editing the entity-machine-type relationship
  /// - newEntityId: Entity ID of the updated relationship
  /// - newMachineType: Master machine type of the updated relationship
  /// - lab: id for the lab
  async updateBadgeMachineTypeRelationship(UID, oldEntityId, updatedEntityId, oldMachineType, updatedMachineType, lab) {
    var badgeInfo = await this.apiCall(`/badgr_requests/badge_class_info/${updatedEntityId}`, "GET", { UID: UID })

    //Throw an error if the call to the Badgr API fails
    if (badgeInfo.status !== 200) {
      return { status: 400, error: "Not an existing Badge ID" }
    } else {
      return await this.apiCall("/badges/", "PATCH", {
        UID: UID,
        badgeclass_entity_id: oldEntityId,
        new_badgeclass_entity_id: updatedEntityId,
        old_machineType: oldMachineType,
        new_machineType: updatedMachineType,
        lab: lab
      });
    }
  },

  /// Deletes a Badge-Machine-Type relationship
  /// - UID: The University ID of the User deleting the entity-machine-type relationship
  /// - entityId: Entity ID of the relationship being deleted
  /// - machineType: Master machine type of the relationship being deleted
  /// - lab: id for the lab
  async deleteBadgeMachineTypeRelationship(UID, entityId, machineType, lab) {
    return await this.apiCall("/badges/", "DELETE", {
      UID: UID,
      badgeclass_entity_id: entityId,
      machineType: machineType,
      lab: lab
    });
  },

  casLogin() {
    return this.apiCall('/authentication/login', "GET");
  },

  casLogout() {
    return this.apiCall('/authentication/logout', "GET");
  },

  casLoginCallback(ticket, next) {
    return this.apiCall('/authentication/login', "GET", { 'ticket': ticket, 'next': next });
  },

  badgrOAuth(code, UID) {
    return this.apiCall('/badgr_requests/do_OAuth2', 'GET', { code: code })
  },
};

// See https://create-react-app.dev/docs/adding-custom-environment-variables/ for an explanation of the global environment variable system
// This allows switching between base URLs depending on whether we run `npm start` or `npm run-script build`
// Will break deployments if urls change.
const Backend = Object.create(apiHook);


// Get the origin of the current URL
const currentOrigin = new URL(window.location.href).origin;

// Define default backend URL
let backendURL = "http://localhost:3001";

// Check if the current tab's URL origin points to legacy prod
if (currentOrigin === "https://pinpoint.engr-prod-tw.aws.umd.edu") {
  backendURL = "https://pinpoint.engr-prod-tw.aws.umd.edu";
}
// Check if the current tab's URL origin points to stage
else if (currentOrigin === "https://stage.pinpoint.umd.edu") {
  backendURL = "https://stage.pinpoint.umd.edu";
}
// Check if the current tab's URL points to new prod
else if (currentOrigin === "https://pinpoint.umd.edu") {
  backendURL = "https://pinpoint.umd.edu";
}

// Append '/api/v1' to the backend URL
const finalURL = backendURL + "/api/v1";

// Set the URL for the backend
Backend.setURL(finalURL);

// Expose the Backend object to other files
export default Backend;
