import React, { useState, useEffect, useLayoutEffect } from "react";
import "./MachineMenu.css";
import Backend from "../../../utils/api.js";
import {
    Alert, 
    AlertIcon, 
    AlertTitle, 
    AlertDescription, 
    Input
} from "@chakra-ui/react";
import _ from 'lodash';

// This represents a list of different options to choose from, such as labs or machines
// permissionFunction and checkPermissionFunction are optional parameters used to gray out unpermitted options
export default function MachineMenu({ uid, labId, selected, onClick, permissionFunction, checkPermissionFunction, }) {
    // Set the states for the component
    const [filterText, setFilterText] = useState("");
    const [data, setData] = useState([]);
    const [permissions, setPermissions] = useState(null);
    const [machineTypes, setMachineTypes] = useState({});
    const [serverCallFailed, setServerCallFailed] = useState(false);

    // Selectable and unselectable (grayed out) data items
    const [selectableData, setSelectableData] = useState([]);
    const [unselectableData, setUnselectableData] = useState([]);

    // User textbox that updates machine list accordingly
    const changeFilter = (text) => {
        setFilterText(text.target.value.toLowerCase())
    };

    const getMachineData = () => {
        // Get the machine data from the backend

        Backend.getMachineByLab(uid, labId)
            .then(({items, status}) => {
                if(status === 200){
                    if (! _.isEqual(data, items)) {
                        setData(items);
                    }
                } else {
                    setServerCallFailed(true);
                }
            })
            .catch(error => {
                setServerCallFailed(true);
            });

        // Get the user's permissions from the backend
        if (permissionFunction !== undefined) {
            permissionFunction()
                .then(({ items, status }) => {
                    if(status === 200) {
                        if (! _.isEqual(permissions, items)) {
                            setPermissions(items);
                        }
                    } else {
                        setServerCallFailed(true);
                    }
                })
                .catch(error => {
                    setServerCallFailed(true);
                });
        }

        // Get the machine types from the backend
        Backend.getMachineTypes(uid, 1000)
            .then(({items, status}) => {
                if(status === 200) {
                    // Create a JSON for the machine types
                    let machineTypesJson = {};

                    // Loop through all of the items in the array
                    items.forEach((element) => {
                        // Add the machine type to the JSON
                        machineTypesJson[element.id] = element.name;
                    });

                    // Set the state
                    if (! _.isEqual(machineTypes, machineTypesJson)) {
                        setMachineTypes(machineTypesJson);
                    }
                } else {
                    setServerCallFailed(true);
                }
            })
            .catch(error => {
                setServerCallFailed(true);
            });
    }

    useEffect(() => {
        getMachineData()
    });

    // Set selectable and unselectable data
    useEffect(() => {
        let selectable = [];
        let unselectable = [];
        if (checkPermissionFunction === undefined) {
            selectable = data;
        } else {
            data.map((e) => {
                if (checkPermissionFunction(permissions, e)) {
                    selectable.push(e);
                } else {
                    unselectable.push(e);
                }
            });
        }

        setSelectableData(selectable);
        setUnselectableData(unselectable);
    }, [data, permissions]);

    // Check if the server calls failed
    if(serverCallFailed === true) {
        // Return an alert component
        return (
            <div className="AlertContainer">
                <Alert
                    status='error'
                    variant='top-accent'
                    flexDirection="column"
                    alignItems="center"
                    justifyConent="center"
                    textAlign="center"
                    height="200px"
                    my="2vh"
                >
                    <AlertIcon boxSize="40px" mr={0} />
                    <AlertTitle mt={4} mb={1} fontSize="lg">Error</AlertTitle>
                    <AlertDescription maxWidth="sm">
                        There was an error retrieving machine and permission data from the server.
                    </AlertDescription>
                </Alert>
            </div>
        );
    } else {
        // Return a UI for the menu
        return (
            <div className="MachineMenu">
                <div className="FilterInput">
                    <Input
                        value={filterText}
                        variant="filled"
                        placeholder="Search for a machine..."
                        onChange={changeFilter}
                    />
                </div>

                <div className="MenuDirections">
                    <p>Click on a machine to edit it</p>
                </div>

                <div className="MenuContent">
                    <ul>
                        {
                            // Items the user has permissions for
                            selectableData.filter(
                                datum => datum.name.toLowerCase().includes(filterText)
                            ).map((e) => {
                                // Get the name of the machine type
                                const machineType = e.machineType ? machineTypes[e.machineType] : null;
                                
                                // Render a card for the machine
                                return (
                                    <MachineMenuEntry
                                        key={e.name}
                                        selected={selected === e ? true : false}
                                        onClick={() => onClick(e)}
                                        name={e.name}
                                        machineType={machineType}
                                        selectable={true}
                                    />
                                );
                            })
                        }
                        {
                            // Items the user doesn't have permissions for
                            unselectableData.filter(datum =>
                                datum.name.toLowerCase().includes(filterText)
                            ).map((e) => {
                                // Get the name of the machine type
                                const machineType = e.machineType ? machineTypes[e.machineType] : null;
                                
                                // Render a card for the machine
                                return (
                                    <MachineMenuEntry
                                        key={e.name}
                                        selected={false}
                                        onClick={null}
                                        name={e.name}
                                        machineType={machineType}
                                        selectable={false}
                                    />
                                );
                            })
                        }
                    </ul>
                </div>
            </div>
        );
    }
}

// Component for the machine cards
function MachineMenuEntry(props) {
    return (
        <li style={props.style}>
            <div
                onClick={props.onClick} 
                className={`MachineMenuEntry ${props.selected ? 'selected' : 'unselected'} ${props.selectable ? 'selectable' : 'unselectable'}`}
            >
                <h3>{props.name}</h3>
                {props.machineType ? <p>{`${props.machineType}`}</p> : <></>}
            </div>
        </li>
    );
}
