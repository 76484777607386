import React from 'react';
import {withRouter} from "react-router-dom";
import './Submission.css';
import { Button } from '@chakra-ui/react'

function Submission({success, onClick}){
   return (
      <div className="submission-container">
         <br />
         {success ? 
            <>
               <div className="submission-message">Success!</div>
               <i className="check-icon fa fa-check-circle"></i><br></br>
               <div className='continue-button'><Button colorScheme='primary' onClick={onClick}>Click to continue</Button></div>
            </>
            :
            <>
               <div className="submission-message">Database Failure!</div>
               <i className="error-icon fa fa-times-circle"></i><br></br>
               <div className='continue-button'><Button colorScheme='primary' onClick={onClick}>Click to continue</Button></div>
            </>
         }

      </div>
   );
}

export default withRouter(Submission);
