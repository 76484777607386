import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Backend from "../utils/api.js";
import pinpointLogo from "../assets/pinpoint_no_bg.svg";
import "./LabStatus.css";
import UserCard from "./Components/UserCard.jsx";
import {
  Stack,
  Image,
  chakra,
  Text,
} from "@chakra-ui/react";

/**
 * Page that populates live with the various users swiped into the current lab, including
 * their selected machines and times they've been signed in. Admins who are signed in are
 * displayed on the top left of the page ahead of all other users.
 */
function LabStatus({ currLab }) {
  const [userCount, setUserCount] = useState(0); // keeps count of active users in the lab
  const [userList, setUserList] = useState([]);
  const currLabName = (currLab && currLab.name) || "Loading";
  const updateInterval = 30000;
  const userListBuilder = [];

  // Grabs the machines that a user is actively using in the lab
  async function getUserMachines(user) {
    // Calls backend endpoint that grabs the active machines for a user in a lab
    let machineList = await Backend.getActiveMachinesByUserForLab(
      user.UID,
      currLab.id
    );
    const activeMachines = [];
    // Iterates through each machine in the list and pushes the string name of the active machine into the activeMachines list
    machineList = Object.values(machineList);
    for (let val of machineList) {
      if (val.machineName) activeMachines.push(val.machineName);
    }
    return activeMachines;
  }

  useEffect(() => {
    // Function to fetch and update data
    const fetchData = async () => {
      if (currLab.id !== undefined) {
        // Obtain the users actively using the lab
        const data = await Backend.getActiveUsersByLab(currLab.id, 1000);
        const activeUsers = data.items;

        // Create an array of promises for fetching user data
        const promises = activeUsers.map(async (user) => {
          const userMachines = await getUserMachines(user);
          const userSwipeInTime = await Backend.getUserSwipeInTime(user.UID, currLab.id);
          return [user, userMachines, userSwipeInTime];
        });
        
        // Wait for the promises to resolve
        const userListBuilder = await Promise.all(promises);

        // Now that the promises are resolved, update the states
        setUserCount(activeUsers.length);
        setUserList(userListBuilder);
      }
    };
    
    // Initial fetch
    fetchData();

    // Use setInterval to update the page every minute
    const intervalId = setInterval(fetchData, updateInterval);

    // Clear the interval when the component unmounts or rerenders
    return () => clearInterval(intervalId);
  }, [currLab.id]);

  return (
    <Stack>
      <Image
        alignSelf="start"
        width="3em"
        src={pinpointLogo}
        alt="pinpoint logo"
        margin="2vh"
        position={"absolute"}
      ></Image>

      <chakra.h1
        textAlign={"center"}
        fontSize={"5xl"}
        py={"1vh"}
        fontWeight={"bold"}
      >
        {currLabName}
      </chakra.h1>

      <chakra.h2 textAlign={"center"} fontSize={"xl"} py={0.5}>
        View all active lab users and their selected machines here!
      </chakra.h2>

      <chakra.h3 textAlign={"center"} fontSize={"m"} py={0.5} color="darkgrey">
        Updated every {updateInterval/1000} seconds.
      </chakra.h3>

      <chakra.h2
        textAlign={"right"}
        fontSize={"xl"}
        py={-50}
        fontWeight={"bold"}
        paddingRight="2rem"
      >
        Active users: {userCount}
      </chakra.h2>
      {userList.length > 0 ? (
        <div className="CardContainer">
          {userList.map((entry) => {
            let user = entry[0];
            let machines = entry[1];
            let swipeInTime = entry[2];

            return <UserCard
                      key={user.UID}
                      user={user}
                      machines={machines}
                      swipeInTime={swipeInTime}
                    />;
          })}
        </div>
      ) : (
        <div className="NoUserText">
          <Text 
            fontSize="3xl" 
            textAlign="center"
          >
            No active users
          </Text>
        </div>
      )}
    </Stack>
  );
}

export default withRouter(LabStatus);
