import React from "react";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Backend from "../utils/api.js";
import { withRouter } from "react-router-dom";
import SearchBar from "./Components/SearchBar.jsx";
import ToolCard from "./Components/ToolCard.jsx";
import "../utils/default.css";
import {
  Flex,
  Text,
  chakra,
  SimpleGrid,
  Button,
  Stack,
  Link,
  Spinner,
  Select,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  ModalCloseButton,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from '@chakra-ui/icons'
import ProfileAppBar from "./Components/ProfileAppBar.jsx";
import { useHistory } from "react-router-dom";

/*
 * This component is the main page for the user to reserve tool(s).
 * It displays a list of tools that the user can select from and asks for a reason.
 * The user can also search for specific tools.
 */
function ToolSelection({ user, lab }) {
  const history = useHistory(); // used to redirect to the next page
  const [isLoadingPermissions, setIsLoadingPermissions] = useState(false);
  const [permissionLoadingError, setPermissionLoadingError] = useState(false);
  const [noCanvasBadgesIntegrationError, setNoCanvasBadgesIntegrationError] = useState(false);
  const [machines, setMachines] = useState([]); // stores list of machines available in the active lab
  const [query, setQuery] = useState(""); // stores the current search query
  const [isSubmitting, setIsSubmitting] = useState(false); // stores whether the user is currently submitting a reservation (used for loading purposes)
  const [selectedReason, setSelectedReason] = useState(""); // stores the reason the user selected for reserving the tool(s)
  const [reserveToolError, setReserveToolError] = useState(false); // stores boolean on whether a tool reservation error should be made if no tool/reason is selected
  const [machineTypes, setMachineTypes] = useState([]) // stores the machineTypes a user has permission to
  const [usingBadgrIntegration, setUsingBadgrIntegration] = useState(undefined) // stores whether this lab is using canvas badges
  const [allMachineTypes, setAllMachineTypes] = useState([]) // stores all machine types
  const [isOpen, setIsOpen] = useState(false)
  const onClose = () => setIsOpen(false)
  const [isPermissionWarningOpen, setIsPermissionWarningOpen] = useState(false);
  const [machineTypeWarn, setMachineTypeWarn] = useState("N/A");
  const [signInCompleted, setSignInCompleted] = useState(false); // Used to display sign in success popup
  const [signInError, setSignInError] = useState(false); // used to display sign in error popup
  const [categories, setCategories] = useState([]);

  // hardcoded of reasons the user can select from to reserve the tool(s)
  let reasonOptions = [
    "Research Project",
    "Student Project",
    "Personal Project",
    "Student Team Project",
    "Start-up",
    "Workshop",
  ];

  function getUserMachineTypePermissions() {
    setIsLoadingPermissions(true);

    Backend.getMachineTypesByUser(user.UID)
    .then((res) => {
      setIsLoadingPermissions(false);
      if (res.status === 401) {
        setNoCanvasBadgesIntegrationError(true);
      } else if (res.status !== 200) {
        setPermissionLoadingError(true);
      } else {
        delete res.status;
        setMachineTypes(Object.values(res));
      }
    })
    .catch((res) => {
      setIsLoadingPermissions(false);
      setPermissionLoadingError(true);
    });
  }

  function checkLabBadgrIntegration() {
    Backend.getLabById({id: lab.id, UID: user.UID}).then((res) => {
      setUsingBadgrIntegration(res.badgrIntegration);
      if (res.badgrIntegration) {
        getUserMachineTypePermissions();
      }
    });
  }

  function loadAllMachineTypes() {
    Backend.getMachineTypes(user.UID, 1000).then((res) => {
      delete res.status;
      setAllMachineTypes(Object.values(res)[0]);
    })
  }

  function machineTypeIdToName(id) {
    return allMachineTypes.find((ele) => ele.id.toString() == id.toString());
  }

  useEffect(() => {
    checkLabBadgrIntegration();
    loadAllMachineTypes();
    
    //try to add backendcode here where it returns the array by lab and then you can sort that and display it down there
    Backend.getToolCategoriesByLab(user.UID, lab.id, 1000).then((x) => {
      let array = [...x.items];
      setCategories(array);
      //console.log(array);
    });
    // console.log(Ids);
    //});
    //this promise actually returns a rejection so this isnt working out

    // get list of machines available in the active lab
    Backend.getMachineByLab(user.UID, lab.id).then((availableMachines) => {
      //console.log(availableMachines);
      let newMachines = [...availableMachines.items]; // create a copy of the list of machines
      newMachines.forEach((machine, index) => {
        newMachines[index].isSelected = false;
        newMachines[index].isVisible = true;
        newMachines[index].categoryName = machine.machineType || "Unknown";
      });
      setMachines(newMachines);
    });
  }, [user.UID, lab.id, lab.name, user.firstName, user.lastName, lab.badgrIntegration]);

  // filter out displayed machines based on active search query
  useEffect(() => {
    let newMachines = [...machines];

    if (query.length > 0) {
      // if search bar is not empty, filter out machines that do not match the query
      machines.forEach((machine, index) => {
        newMachines[index].isVisible =
          machine.name.toLowerCase().includes(query.toLowerCase()) ||
          machine.categoryName?.toLowerCase().includes(query.toLowerCase());
      });
      setMachines(newMachines);
    } else {
      // if search bar is empty, display all machines
      machines.forEach((machine, index) => {
        newMachines[index].isVisible = true;
      });
      setMachines(newMachines);
    }
  }, [query]);

  // Scroll to the bottom of page when alert confirming tool checkout appears
  useEffect(() => {
    if (signInCompleted || signInError) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [signInCompleted, signInError]);

  function getCatArray(array) {
    let arrayCat = new Set();
    for (var i = 0; i < array.length; i++) {
      arrayCat.add(array[i].categoryID);
    }

    return Array.from(arrayCat);
  }

  if (isLoadingPermissions) {
    return (
      <Flex
      pt={5}
      px={{ base: 2, sm: 12, md: 17 }}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      >

        <Spinner></Spinner>
        <chakra.h1
          textAlign={"center"}
          fontSize={"3xl"}
          py={"1vh"}
          fontWeight={"bold"}
        >
          Loading your tool permissions... Please wait a second.
        </chakra.h1>
      </Flex>
    );
  } else if (permissionLoadingError) {
    return (
      <Flex
      pt={5}
      px={{ base: 2, sm: 12, md: 17 }}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      >

        <chakra.h1
          textAlign={"center"}
          fontSize={"3xl"}
          py={"1vh"}
          fontWeight={"bold"}
        >
          Uh Oh!
        </chakra.h1>
        <Text
          textAlign={"center"}
          fontSize={"md"}
        > 
        We were unable to load your tool permissions from Canvas Badges. This likely means that you either haven't setup an account with Canvas Badges, you haven't authenticated your account
        with Pinpoint, or there was an error with your access token.
        
        Check that you have a Canvas Badges account, and that you've authenticated with Pinpoint by going to your profile page and clicking "Authenticate with Canvas Badges".
        </Text>
      </Flex>
    );

  } else if (noCanvasBadgesIntegrationError) {
    return (
      <Flex
      pt={5}
      px={{ base: 2, sm: 12, md: 17 }}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      >

        <chakra.h1
          textAlign={"center"}
          fontSize={"3xl"}
          py={"1vh"}
          fontWeight={"bold"}
        >
          This lab requires Canvas Badges
        </chakra.h1>
        <Text
          textAlign={"center"}
          fontSize={"md"}
        > 
        You have not yet authenticated Pinpoint with your Canvas Badges account. Head to the 
          <Link color='teal.500' href='https://pinpoint.umd.edu/'> profile panel for Pinpoint <ExternalLinkIcon mx='1px'/></Link>, and select "Authenticate with Canvas Badges". 
        </Text>
      </Flex>
    );
  } else {
    return (
      <Flex
        pt={5}
        px={{ base: 2, sm: 12, md: 17 }}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <ProfileAppBar user={user} signoutLocation="/kiosk" />
  
        <chakra.h1
          textAlign={"center"}
          fontSize={"3xl"}
          py={"1vh"}
          fontWeight={"bold"}
        >
          Welcome, {user.firstName}!
        </chakra.h1>
  
        <chakra.h2 textAlign={"center"} fontSize={"xl"} py={3}>
          Please select the tools you would like to use today
        </chakra.h2>
        <Flex
          height="55vh"
          width="60vw"
          alignItems="center"
          flexDirection="column"
          marginTop="3vh"
        >
          <SearchBar query={query} setQuery={setQuery}></SearchBar>
          {machines.length > 0 && categories.length > 0 && usingBadgrIntegration !== undefined ? (
            <Flex flexDirection="column" alignItems="center">
              <Box display="flex" width="80vw" px="1.5vw">
                <SimpleGrid
                  spacingX={"20vw"}
                  spacingY={"5vh"}
                  my="5vh"
                  whiteSpace="nowrap"
                  width="65vw"
                >
                  {getCatArray(machines).sort((cat_a_id, cat_b_id) => {
                    let cat_a = categories.find((ele) => ele.categoryID == cat_a_id);
                    let cat_b = categories.find((ele) => ele.categoryID == cat_b_id);
                    return cat_a.categoryName.localeCompare(cat_b.categoryName);
                  })
                  .map((catId) => {
                    console.log(categories);
                    let heading = "";
                    // for (var i = 0; i < categories.length; i++) {
                    //   if (categories[i].categoryID == catId) {
                    //     heading = categories[i].categoryName;
                    //   } else {
                    //     heading = "CategoryId: " + catId;
                    //   }
                    // }
                    //
                    let category = categories.find(
                      (ele) => ele.categoryID == catId
                    );
                    if (category != undefined) {
                      heading = category.categoryName;
                    } else {
                      heading = "Not Available";
                    }
  
                    return (
                      <div>
                        <chakra.h2 fontSize={"2xl"} fontWeight="bold">{heading}</chakra.h2>
                        <SimpleGrid
                          columns={[1, 1, 2, 3, 4]}
                          //autoColumns="true"
                          spacingX={"20vw"}
                          spacingY={"5vh"}
                          columnGap={[ "0vw", "30vw", "35vw", "20vw", "16vw"]}
                          my="5vh"
                          whiteSpace="nowrap"
                          marginLeft={[0, "18vw", 0, 0, 0]}
                        >
                          {machines
                            .filter((machine) => machine.isVisible)
                            .sort((machine_a, machine_b) => machine_a.name.localeCompare(machine_b.name))
                            .map((machine, index) => {
                              let userHasPermission; 
  
                              if (!usingBadgrIntegration) {
                                userHasPermission = true;
                              } else if (machineTypes.length == 0) {
                                userHasPermission = false;
                              } else if (machineTypes.find((ele) => ele.id.toString() === machine.machineType) !== undefined) {
                                userHasPermission = true;
                              } else {
                                userHasPermission = false;
                              }
  
                              if (machine.categoryID == catId) {
                                return (
                                  <ToolCard
                                    isDisabled={!userHasPermission}
                                    key={machine.name + index}
                                    type={machine.categoryName || "Null"}
                                    name={machine.name}
                                    isSelected={machine.isSelected}
                                    onClick={() => {
                                      if (userHasPermission) {
                                        console.log("348, user has permission");
                                        const updatedMachines = machines.map(m => {
                                            if (m.id === machine.id) {
                                                return {...m, isSelected: !m.isSelected};
                                            }
                                            return m;
                                        });
                                        setMachines(updatedMachines);
                                      } else {
                                        console.log("354, user does not have permission");
                                        setIsPermissionWarningOpen(true);
                                        console.table(allMachineTypes);
                                        let machineType = machineTypeIdToName(machine.machineType);
                                        setMachineTypeWarn(machineType);
                                      }
                                    }}
                                  />
                                );
                              }
                            })}
                        </SimpleGrid>
                      </div>
                    );
                  })}
                </SimpleGrid>
              </Box>
              <chakra.h2 fontSize={"xl"}>
                Please select a reason for using the lab today
              </chakra.h2>
              <Select
                placeholder="Select a reason*"
                onChange={(e) => {
                  setSelectedReason(e.target.value);
                }}
                my="3vh"
              >
                {reasonOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
              <Flex direction="column" align="center" my="3vh" mx="lg">
                <Button
                  height="6vh"
                  my="3vh"
                  borderRadius="2vh"
                  shadow={"xl"}
                  maxW="250"
                  isLoading={isSubmitting}
                  loadingText="Reserving Tool(s)..."
                  onClick={() => {
                    let selectedMachines = machines.filter(
                      (machine) => machine.isSelected
                    );
                    if (selectedMachines.length > 0 && selectedReason !== "") {
                      setIsSubmitting(true);
                      Backend.newRequest({
                        UID: user.UID,
                        machineIds: selectedMachines.map((machine) => machine.id),
                        reason: selectedReason,
                        lab: lab.id
                      }).then((res) => {
                        if (res.status == 201) {
                          setSignInCompleted(true);
                          setTimeout(() => history.push("/kiosk"), 3000)
                        } else {
                          setSignInError(true);
                          setTimeout(() => setSignInError(false), 3000);
                          setIsSubmitting(false);
                        }
                      })
                    } else {
                      setReserveToolError(true);
                      setIsOpen(true);
                    }
                  }}
                >
                  Reserve Tool(s)
                </Button>
                <Text
                  maxW="400"
                  my="1vh"
                  style={{
                    color: "#ee201f",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSignInCompleted(true)
                    setTimeout(() => history.push("/kiosk"), 3000)
                  }}
                >
                  Proceed Without Selecting Tool(s)
                </Text>
                {signInCompleted ? <Alert status='success' variant='left-accent' my='2vh'>
                  <AlertIcon />
                  <AlertTitle>Submitted!</AlertTitle>
                  <AlertDescription>Thank you for signing in using Pinpoint! Please don't forget to sign out when leaving.</AlertDescription>
                  <Button 
                  maxW="20vh"
                  variant="ghost"
                  onClick={ () => {
                    history.push('/kiosk')
                  }}>
                    Continue
                  </Button>
                  <Text></Text>
                </Alert> :<></> }
                {signInError ? <Alert status='error' variant='left-accent' my='2vh'>
                  <AlertIcon />
                  <AlertTitle>Error</AlertTitle>
                  <AlertDescription>There was an error processing your request. 
                    Please try again or contact a lab staff.</AlertDescription>
                </Alert> : <></>}
              </Flex>
              {reserveToolError ? (
                <Modal isOpen={isOpen} onClose={onClose}>
                  <ModalOverlay />
                  <ModalContent h="25vh" w="40vw">
                    <ModalHeader style={{ color: "red" }}>Error</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      You must select at least 1 tool and a reason for using the
                      lab, or select "Proceed without selecting tool(s)" if you
                      don't plan on using any equipment today.
                    </ModalBody>
                  </ModalContent>
                </Modal>
              ) : (
                <></>
              )}
              {isPermissionWarningOpen ?
                <Modal isOpen={isPermissionWarningOpen} onClose={() => {setIsPermissionWarningOpen(false)}}>
                  <ModalOverlay />
                  <ModalContent h='40vh' w='40vw'>
                    <ModalHeader style={{ color: 'Orange' }}>Permission Warning!</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>This lab uses Canvas Badges to grant users permission to use certain tools.
                      You can't select this tool because you haven't earned its badge yet. If you'd like to use this tool,
                      you'll need to complete the required course(s) on Canvas. Contact a lab staff for more information.
  
                      <br></br>
                      <br></br>
                      <Text as="i" fontSize={"sm"}>The machine type of this tool is: {machineTypeWarn.name}</Text>
                    </ModalBody>
                  </ModalContent>       
                </Modal>
             : <></>}
            </Flex>
          ) : (
            <Spinner my={"15vh"} />
          )}
        </Flex>
      </Flex>
    );
  }


  
}
ToolSelection.propTypes = {
  user: PropTypes.shape({
    UID: PropTypes.number.isRequired,
  }).isRequired,
  lab: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(ToolSelection);
