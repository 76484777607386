import { useEffect, useState } from "react";
import Backend from "../utils/api";
import {
  Box,
  Text,
  useColorModeValue,
  Stack,
  Image,
  Heading,
  Flex,
} from "@chakra-ui/react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import pinpointLogo from "../assets/pinpoint_no_bg.svg";

const logout = () => {
  Backend.casLogout().then((res) => {
    setTimeout(() => window.location.replace(res.redirect), 3000);
  });
};

function LogoutCas() {
  useEffect(() => {
    logout();
  }, [])

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={20} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Image
          alignSelf="center"
          width="4em"
          src={pinpointLogo}
          alt="pinpoint logo"
          margin="2vh"
          position={"absolute"}
        ></Image>
        <Stack align={"center"}>
          <Heading fontSize={"5xl"} fontWeight="bold">
            Pinpoint
          </Heading>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Text fontSize={"md"} color={"gray.600"} align={"center"}>
            Thanks for using Pinpoint! You will be redirected to the CAS signout page in 3 seconds.
          </Text>
        </Box>
      </Stack>
    </Flex>
  );
}

export default withRouter(LogoutCas);
