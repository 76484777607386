import {
    Text,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from "@chakra-ui/react";
  
  import { useHistory } from "react-router-dom";
  
  /* A modular popup used to indicate to the user that an error has occurred. */
  export default function SwipeOutReminderPopup({ isOpen, onClose, onCheckout }) {
    const history = useHistory();
    const header = "Remember to swipe out!";
    const message = `Last time you used a lab, you forgot to sign out when you 
    were done. Please remember to swipe out whenever you are finished using a 
    lab.`;
    return (
      <>
        <Modal isOpen={isOpen} onClose={onClose} isCentered="true">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{header}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>{message}</Text>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => {
                  onCheckout();
                  history.push("/machine_selection");
                  onClose();
                }}
              >
                I Understand
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }
  