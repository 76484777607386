import { Box, Stat, StatLabel, StatNumber, Text, Textarea} from "@chakra-ui/react";
import React, { useState, useEffect, useRef } from "react";
import { CheckCircleIcon } from "@chakra-ui/icons";

/*
  A selectable card component used to display a tool's information.
*/
function ToolCard({ key, type, name, isSelected, onClick, isDisabled }) {
  let backgroundColor = undefined
  if (isDisabled) {
    backgroundColor = "#575757"
  } else if (isSelected) {
    backgroundColor = "#F4FDF3"
  }

  const MAX_NAME_LENGTH = 80;
  const verticalAlignment = name.length > MAX_NAME_LENGTH ? "flex-start" : "center";

  return (
    <Stat
      key={key}
      onClick={onClick}
      _hover={{
        cursor: "pointer",
        transform: "scale(1.05)",
        transition: "transform .2s",
      }}
      width={[ "0vw", "40vw", "25vw", "20vw", "16vw"]} // Conditionally set width to fit screen size
      px={{ base: 2, md: 2 }}
      py={"5"}
      shadow={"md"}
      border={"1px solid"}
      borderColor={isSelected ? "green" : "black"}
      rounded={"lg"}
      textAlign={"center"}
      backgroundColor={backgroundColor}
    >
      {isSelected && (
        <Box position="absolute" top="1" left="1">
          <CheckCircleIcon color="green.500" boxSize={6} />
        </Box>
      )}
      <StatLabel isTruncated
        fontSize={"xl"}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: verticalAlignment,
          whiteSpace: "normal",
          height: "4.5em",
        }}
      >
        <Box
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100%",
          }}
      >
        {name}
      </Box>
      </StatLabel>
    </Stat>
  );
}

export default ToolCard;
