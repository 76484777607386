import React from "react";
import { withRouter } from "react-router-dom";

import {
  Flex,
  Heading,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

/* A screen used to indicate to the user that an error as occurred. */
function InvalidScreen() {
  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>Error</Heading>
          <Text fontSize={"lg"} color={"gray.600"}>
            Please try again.
          </Text>
        </Stack>
      </Stack>
    </Flex>
  );
}

export default withRouter(InvalidScreen);
