import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import { SearchIcon, CloseIcon } from "@chakra-ui/icons";

/* 
 An interactive search bar component used to search for tools. 
*/
function SearchBar({ query, setQuery }) {
  const handleClearQuery = () => {
    setQuery("");
  };

  return (
    <Box width="xl">
      <InputGroup size="xl">
        <InputLeftElement
          pointerEvents="none"
          children={<SearchIcon color="gray.500" />}
          paddingLeft="1vw"
          py="1.75vh" /* add some padding to the icon */
        />
        <Input
          type="text"
          placeholder="Search by tool name or category"
          variant="filled"
          size="md"
          borderRadius="full"
          value={query}
          onChange={(event) => setQuery(event.target.value)}
          _hover={{ borderColor: "gray.300" }}
          _focus={{ borderColor: "gray.500" }}
          px="3vw" /* add some padding to the input field */
        />
        <InputRightElement>
          {query ? (
            <IconButton
              aria-label="Clear search query"
              icon={<CloseIcon />}
              size="sm"
              onClick={handleClearQuery}
              padding="0 8px" /* add some padding to the icon */
              marginTop="4px" /* adjust the vertical alignment of the icon */
            />
          ) : null}
        </InputRightElement>
      </InputGroup>
    </Box>
  );
}

export default SearchBar;
