import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Flex,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useHistory, withRouter } from "react-router-dom";
import Backend from "../utils/api.js";
import Validators from "../utils/validators.jsx";
import ProfileAppBar from "./Components/ProfileAppBar.jsx";

/*
 * This component provides a full view of the authenticated user's "profile."
 * Provides a way to edit user information and connect their account with Canvas Badges.
 * Additionally provides an entry point to the Admin Dashboard for admins.
 */
const Profile = ({ user }) => {
  const [userState, setUserState] = useState(user); // stores state of user as fields are edited
  const [showMessage, setShowMessage] = useState(false); // stores state of whether or not to show a message
  const [messageText, setMessageText] = useState(""); // stores message to be displayed
  const [changesSaved, setChangesSaved] = useState(false);
  const [authComplete, setAuthComplete] = useState(false); // stores state of whether the current user has already auth'd with Badgr or not

  // Displays an auto-disappearing message to the user
  function flashMessage(message) {
    setShowMessage(true);
    setMessageText(message);
    setTimeout(() => {
      setShowMessage(false);
      setMessageText("");
    }, 3000);
  }

  /*
  // If the user clicks the back button, we want to redirect them to the CAS logout.
  const history = useHistory();
  useEffect(() => {
    window.onpopstate = () => {
      history.push("/logout");
    }
  }, []);
  */

  // Check whether the user has authenticated with Badgr or not yet
  useEffect(() => {
    // Get the user's information from the backend
    Backend.checkBadgrAuthentication(userState.UID)
      .then((response) => {
        // Change the state to true if the user has authenticated with Badgr already
        if(response.status === 200 && response.isAuthd == true) {
          setAuthComplete(true);
        } else {
          setAuthComplete(false)
        }
      });
  }, []);

  return (
    <Flex display="column">
      <ProfileAppBar user={user} signoutLocation="/logout" />
      <Box p={16} display={{ md: "flex" }} justifyContent="center">
        <Box width={{ md: 550 }}>
          <Heading as="h1" size="xl" mb={8}>
            Hey {userState.firstName} 👋
          </Heading>
          <Text>
            Welcome to Pinpoint. Here, you can update your profile and connect
            your Canvas Badges account for use with makerspaces that participate
            in tool management.
          </Text>
          <Text
            as="h2"
            fontSize="xl"
            fontWeight={"bold"}
            mb={4}
            marginTop="3vh"
          >
            Edit Profile
          </Text>
          <form
            onSubmit={(e) => {
              if (Validators.validateUser(userState)) {
                setChangesSaved(true);
                setTimeout(() => {
                  setChangesSaved(false);
                }, 3000);
                Backend.editUser({ UID: userState.UID, args: userState });
              } else {
                e.preventDefault();
                console.log("Invalid - Please enter valid user info!"); // TODO: show error message
              }
            }}
          >
            <Flex alignItems="end" justifyContent="space-between">
              <FormControl id="firstName" marginRight="1vw">
                <FormLabel>First Name</FormLabel>
                <Input
                  type="text"
                  placeholder="Enter first name"
                  value={userState.firstName}
                  onChange={(e) => {
                    Validators.constrainNameInput(e.target.value) &&
                      setUserState({ ...userState, firstName: e.target.value });
                  }}
                  isInvalid={!Validators.validateName(userState.firstName)}
                />
              </FormControl>
              <FormControl id="lastName" mt={4}>
                <FormLabel>Last Name</FormLabel>
                <Input
                  type="text"
                  placeholder="Enter last name"
                  value={userState.lastName}
                  onChange={(e) => {
                    Validators.constrainNameInput(e.target.value) &&
                      setUserState({ ...userState, lastName: e.target.value });
                  }}
                  isInvalid={!Validators.validateName(userState.lastName)}
                />
              </FormControl>
            </Flex>
            <FormControl id="email" mt={4}>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                placeholder="Enter email"
                value={userState.email}
                onChange={(e) => {
                  setUserState({ ...userState, email: e.target.value });
                }}
                isInvalid={!Validators.validateEmail(userState.email)}
              />
            </FormControl>
            <FormControl id="uid" mt={4}>
              <FormLabel>University ID</FormLabel>
              <FormLabel fontSize={12}>
                Your University ID cannot be changed.
              </FormLabel>
              <Input
                type="text"
                placeholder="111222333"
                value={userState.UID}
                disabled={true}
              />
            </FormControl>
            <Flex alignItems="end" justifyContent="space-between">
              <FormControl id="directoryId" marginRight="1vw" mt={4}>
                <FormLabel>Directory ID</FormLabel>
                <Input
                  type="text"
                  placeholder="Enter directory ID"
                  value={userState.directoryID}
                  maxLength={9}
                  onChange={(e) => {
                    setUserState({ ...userState, directoryID: e.target.value });
                  }}
                  isInvalid={
                    !Validators.validateDirectoryID(userState.directoryID)
                  }
                />
              </FormControl>
            </Flex>
            <Button type="submit" variant="solid">
              Save Changes
            </Button>
            {changesSaved ? (
              <Alert status="success" variant="left-accent" my="2vh">
                <AlertIcon />
                <AlertTitle>Success</AlertTitle>
                <AlertDescription>Profile Changes Saved!</AlertDescription>
              </Alert>
            ) : (
              <></>
            )}
            {showMessage && (
              <Text
                alignText="center"
                alignSelf="center"
                color="green.500"
                mt={4}
              >
                {messageText}
              </Text>
            )}
          </form>
        </Box>
        <Box mt={{ base: 10, md: 0 }} width={{ md: 550 }}>
          <Text as="h2" fontSize="xl" fontWeight={"bold"} mb={4}>
            Canvas Badges
          </Text>
          {authComplete ? (
            <Text>
              You've already connected your Canvas Badges account, however you
              can re-connect if you're experiencing issues.
            </Text>
          ) : (
            <Text>
              Connect your Canvas Badges account with Pinpoint for labs that
              require Canvas Badges.
            </Text>
          )}
          <Button
            variant={authComplete ? "outline" : "solid"}
            onClick={() => {
              process.env.REACT_APP_BADGES_URI === undefined
                ? window.location.replace('https://badgr.com/auth/oauth2/authorize?client_id=8NBpZ7FbSqqayCGxgMwdsMrhD3Xq3RZzIIFQwbFg&redirect_uri=https%3A%2F%2Fpinpoint.umd.edu%2Foauth%2Fcallback&scope=r%3Aprofile+r%3Abackpack+rw%3Aissuer')
                : window.location.replace(process.env.REACT_APP_BADGES_URI);
            }}
          >
            {authComplete
              ? "Reconnect to Canvas Badges"
              : "Connect to Canvas Badges"}
          </Button>
        </Box>
      </Box>
    </Flex>
  );
};

Profile.propTypes = {
  user: PropTypes.shape({
    UID: PropTypes.number.isRequired,
  }).isRequired,
};

export default withRouter(Profile);
