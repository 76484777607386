import React from "react";
import Backend from "../../utils/api.js";
import { useHistory } from "react-router-dom";
import { useState, useEffect} from "react";
import { Flex, Text, Avatar, Image, Button } from "@chakra-ui/react";
import pinpointLogo from "../../assets/pinpoint_no_bg.svg";

/* 
 A modular app bar used to display the user's name and sign out button. 
*/
//|| Backend.getLabPermissionsByUser(user.UID).items.length > 0
export default function ProfileAppBar({ user, signoutLocation }) {
  const history = useHistory();
  const [labPermission, setLabPermission] = useState([]);
  //const lab_permission_length = Backend.getLabPermissionsByUser(user.UID);
  //|| lab_permission_length > 0

  useEffect(() => {
    Backend.getLabPermissionsByUser(user.UID).then(({ items, status }) => {
      if (status === 200) {
        setLabPermission(items);
      } else {
        alert("server call failed!");
      }
    });
  }, []);

  return (
    <Flex
      p={3}
      alignItems="center"
      justifyContent="space-between"
      boxShadow="sm"
    >
      <Image
        alignSelf="center"
        width="3em"
        src={pinpointLogo}
        alt="pinpoint logo"
        margin="2vh"
        position={"relative"}
      ></Image>
      <Flex alignItems="center">
        { (user.isSuperAdmin || labPermission.length > 0) ? (
          <Text
          onClick={() => history.push("/admin")}
          cursor="pointer"
        >
          {" "}
          Adminland
        </Text>
        ) : (
          <Flex />
        )}

        <Avatar mx={5} name={`${user.firstName} ${user.lastName}`}></Avatar>
      </Flex>
    </Flex>
  );
}
