import React from 'react';
import './MiniButton.css';

export function MiniButton({onClick, type, children}) {
   if (type == "delete") {
   return (
      <div onClick={onClick} className={"mini-button mini-button-delete"}>
         {children}
      </div>
   );
   } else{
      return (
         <div onClick={onClick} className={"mini-button mini-button-add"}>
            {children}
         </div>
      );
   }
}
