import React, { useState } from 'react';
import './SliderForm.css';
import TextField from '@material-ui/core/TextField';

export default function SubmitButton({submission, errors, fields, onSubmit}) {
    return (<button 
        onClick={
            e => {
                e.preventDefault();
                if (Object.values(errors).filter(e => e !== "").length > 0) { 
                    alert("At least one field is invalid!");
                }
                else if (submission && Object.keys(submission).length === fields.length){
                    onSubmit(submission);
                }
                else {
                    alert("Missing some fields!");
                }
            }
        }
        className="btn btn-outline-primary">
        Submit
    </button>)
}
