import React, { useState } from 'react';
import './SliderForm.css';
import TextField from '@material-ui/core/TextField';
import SubmitButton from './SubmitButton.jsx'
import DeleteButton from './DeleteButton.jsx'

export default function SliderForm({fields, onSubmit, deleteForm = false}) {
   const [submission, setSubmission] = useState({})
   const [errors, setErrors] = useState({})

   const onChange = (e,key) => {
      setSubmission(
         {...submission,
         [key]: e.target.value
         }
      )

      var result = fields.find(obj => obj.key === key)

      if ((result.validate !== "") && !e.target.value.match(result.validate)) { 
         setErrors(
            {...errors,
            [key]: result.errorText
            }
         )
      }
      else { 
         setErrors(
            {...errors,
            [key]: ""
            }
         )
      }
   }

   // Populate the submission object with default values (if they are available)
   const initializeSubmission = () => {
      if (Object.keys(submission).length === 0) {
         let tempSubmission = submission;
         fields.map(f => {
            if (f.defaultValue !== undefined) {
               tempSubmission = {
                  ...tempSubmission,
                  [f.key]: f.defaultValue
               };
            }
         });
         if (Object.keys(tempSubmission).length !== 0) {
            setSubmission(tempSubmission);
         }
      }
      
      return null;
   }
   let button;
   if (deleteForm) {
      button = <DeleteButton
         submission={submission}
         errors={errors}
         fields={fields}
         onSubmit={onSubmit}
      />;
   } else{
      button = <SubmitButton
         submission={submission}
         errors={errors}
         fields={fields}
         onSubmit={onSubmit}
      />
   }
   return (
      <form className="SliderForm">
         {fields.map(f => 
            <TextField 
               required
               margin="dense"
               key={f.key}
               id={f.key}
               label={f.placeholder}
               helperText={errors[f.key]}
               error={ errors[f.key] ? true : false }
               onChange={(e => onChange(e, f.key))}
               value={f.value}
               defaultValue={f.defaultValue}
               disabled = {f.disabled}
            />
         )}
         {initializeSubmission()}
         <div className='centerDiv'>
            {button}
         </div>
      </form>
   );
}