import React, { useEffect, useState } from "react";
import Backend from "../utils/api.js";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";
import pinpointLogo from "../assets/pinpoint_no_bg.svg";
import CustomPopup from "../Components/CustomPopup.jsx";
import {
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  useColorModeValue,
  Image,
  Box,
  Link
} from "@chakra-ui/react";


function LoginCAS({ onSubmit, history }) {
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCASFailure, setIsCASFailure] = useState(false);
  const [isAccountFailure, setIsAccountFailure] = useState(false);
  const nextRedirect = '/profile';

  useEffect(() => {
    const params = new URL(window.location.href).searchParams;
    
    // In the case that ticket is not null (i.e. we have a session), this is a callback from a CAS login
    const ticket = params.get('ticket');
    if (ticket) {
      setIsLoading(true);
      setIsAccountFailure(false);
      setIsCASFailure(false);
      
      Backend.casLoginCallback(ticket, nextRedirect).then((res) => {
        setIsLoading(false);

        // This redirect is where the user will go after a successful cas login.
        if (res.redirect) {
          Backend.getUser(res.user).then((user_res) => {
            // login exists in pinpoint
            if (user_res.status === 200) {
              onSubmit(user_res);
              history.replace(res.redirect);

            // login does not exist in pinpoint
            } else {
              setIsAccountFailure(true);
              onOpen();
            }
          });
        } else {
          setIsCASFailure(true);
          onOpen();
        }
      });
    }
  }, []);

  const login = (e) => {
    e.preventDefault();
    setIsLoading(true);

    Backend.casLogin().then((res) => {
      setIsLoading(false)
      
      // Don't change this redirect! This is where the user is sent to auth with CAS
      window.location.replace(res.redirect); // raw JS (ew)
    });
  };

  return(
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={20} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Image
          alignSelf="center"
          width="4em"
          src={pinpointLogo}
          alt="pinpoint logo"
          margin="2vh"
          position={"absolute"}
        ></Image>
        <Stack align={"center"}>
          <Heading fontSize={"5xl"} fontWeight="bold">
            Pinpoint
          </Heading>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Text fontSize={"md"} color={"gray.600"} align={"center"}>
            Welcome to Pinpoint! Log in to manage your profile and connect your account to Canvas Badges.
          </Text>
          <form onSubmit={login}>
            <Button
              loadingText="Logging In..."
              isLoading={isLoading}
              type="submit"
            >
              Log in with UMD CAS
            </Button>
          </form>
        </Box>
        <Text fontSize={"sm"} color={"gray.500"} align={"center"}>
            <Link
              href="https://docs.pinpoint.umd.edu"
              isExternal>
              Documentation
            </Link>
            &nbsp; &#x2022; &nbsp;
            <Link
              href="https://features.pinpoint.umd.edu"
              isExternal>
              Features
            </Link>
            &nbsp; &#x2022; &nbsp;
            <Link
              href="https://umd.edu/privacy">
              Privacy
            </Link>
            &nbsp; &#x2022; &nbsp;
            <Link
              href="https://umd.edu/web-accessibility">
              Web Accessibility
            </Link>
            <br />
            &copy; {new Date().getFullYear()} University of Maryland
        </Text>
      </Stack>
      {isOpen && isAccountFailure && (
        <CustomPopup
          isOpen={isOpen}
          onClose={onClose}
          header={"Hmm... we couldn't find your account"}
          message={
            "It doesn't look like your account exists in Pinpoint yet. Please visit a makerspace kiosk to create your account before attempting to log in."
          }
        ></CustomPopup>
      )}
      {isOpen && isCASFailure && (
        <CustomPopup
          isOpen={isOpen}
          onClose={onClose}
          header={"Failed to authenticate with CAS"}
          message={
            "CAS was unable to authenticate you. Please contact Division of IT for assistance at itsupport@umd.edu."
          }
        ></CustomPopup>
      )}
    </Flex>
  );
}

LoginCAS.propTypes = {
  onSubmit: PropTypes.func,
};

export default withRouter(LoginCAS)
