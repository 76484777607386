import {
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

import { useHistory } from "react-router-dom";

/* A modular popup used to indicate to the user that an error has occurred. */
export default function SwipeOrReservePopup({ isOpen, onClose, onCheckOut }) {
  const history = useHistory();
  const header = "Hey there!";
  const message = `It looks like you already have an active session in this lab. 
  Would you like to sign out or add new tool(s) to your session?`;
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered="true">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{header}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{message}</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              mr={3}
              onClick={() => {
                onCheckOut();
                history.push("/thank_you");
                onClose();
              }}
            >
              Sign Out
            </Button>
            <Button
              mr={3}
              onClick={() => {
                history.push("/machine_selection");
                onClose();
              }}
            >
              Reserve New Tool(s)
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
