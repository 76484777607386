import React, { useState } from 'react';
import {
   InputLabel,
   FormControl,
   MenuItem,
   Select,
   Button
}from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( ({
   dropDownMenu: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      overflow: 'hidden',
   
      listStyle: 'none',
      width: '100%',
   },
   
   centerDiv: {
      textAlign: 'center',
      marginTop: '1em',
   },
   
   button: {
      textAlign: 'center',
      width: ' fit-content',
   }
   }));


   export default function DropDownMenu({ items, title, fields, defaultChoice, onSubmit }) {
   const classes = useStyles();
   
   const [choice, setChoice] = useState(defaultChoice || '');

   const handleChange = e => { 
      setChoice(e.target.value)
   }

   return (
      <div>
         <FormControl required className={classes.dropDownMenu}>
            <InputLabel htmlFor="requestReason">{title}</InputLabel> 
            <Select
               inputProps={{
                  id: "requestReason"
               }}
               value={choice}
               onChange={handleChange}>
               <MenuItem value="">
                  <em>None</em>
               </MenuItem>
               {fields.map((f, index) => 
                  <MenuItem key={index} value={f}>{f}</MenuItem>
               )}
            </Select>
         </FormControl>
         <div className={classes.centerDiv}> 
            <Button 
               // className={`${classes.button} btn btn-outline-primary`}
               size="medium"
               variant="contained"
               color="primary"
               onClick={e => {
                  e.preventDefault();

                  if (choice === ""){
                     alert("Needs a reason!");
                  }
                  else {
                     onSubmit(choice); 
                  }
               }}>
               Submit
            </Button>
         </div>
         
      </div>
   );
}
