import {
    HStack,
    Box,
    Heading,
    ListItem,
    UnorderedList,
} from "@chakra-ui/react"
import "./UserCard.css";


/**
 * Card component used to display the information of an active lab user. Information includes the user's full
 * name,  the machines they selected, and how long they've been signed into the lab for.
 */
function UserCard({ user, machines, swipeInTime }) {
    // calculates how long the user has been signed into the lab
    const timeStamp = Math.floor(swipeInTime.getTime() / 1000);
    const currentTime = Math.floor(new Date().getTime() / 1000); // current time of the day in seconds
    const diff = Math.floor(currentTime - timeStamp); // calculates time difference in seconds
    const hours = Math.floor(diff / 3600);
    const minutes = Math.floor((diff % 3600) / 60);
    const time = `${hours} hour(s) ${minutes} minute(s)`;

    function Feature({ title, subTitle, desc, ftNote, ...rest }) {
        return (
            <Box
                className="UserCard"
                p={5} shadow='xl'
                borderWidth='1px'
                {...rest}
                rounded='md'
            >
                <Heading fontSize='xl'>{title}</Heading>
                <Heading fontSize='md' marginBottom="2vh">{subTitle}</Heading>
                <div className="ListContainer">
                    <UnorderedList>
                        {desc.map((entry, index) => {
                            return <ListItem key={index} mt={1}>{entry}</ListItem>
                        })}
                    </UnorderedList>
                </div>
                <Heading fontSize='md' marginTop="3vh">{ftNote}</Heading>
            </Box>
        )
    }

    return (
        <HStack spacing={8}>
            <Feature
                title={user.firstName}
                subTitle={user.lastName}
                desc={machines}
                ftNote={time}
            />
        </HStack>
    );
}

export default UserCard;