import {
    Text,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from "@chakra-ui/react";
  
  import { useHistory } from "react-router-dom";
  
  /* A modular popup used to indicate to the user that an error has occurred. */
  export default function AdminPopup({ isOpen, onClose, onCheckout }) {
    const history = useHistory();
    const header = "Hey there!";
    const message = `It looks like you are an admin/superadmin for this lab.
                    Would you like to continue as an admin or a user?`;
    return (
      <>
        <Modal isOpen={isOpen} onClose={onClose} isCentered="true">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{header}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>{message}</Text>
            </ModalBody>
            <ModalFooter>
              <Button
                mr={3}
                onClick={() => {
                  onCheckout();
                  onClose();
                }}
              >
                Continue as User
              </Button>
              <Button
                mr={3}
                onClick={() => {
                  history.push("/admin");
                  onClose();
                }}
              >
                Continue as Admin
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }
  