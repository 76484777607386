/* A class used to standardize patterns required for different input fields across components */
class Validators {
  /* 
  Validates all fields in user object
  Returns true if all fields are valid, false otherwise
  */
  static validateUser(user) {
    return (
      Validators.validateName(user.firstName) &&
      Validators.validateName(user.lastName) &&
      Validators.validateUID(user.UID) &&
      Validators.validateEmail(user.email) &&
      Validators.validateDirectoryID(user.directoryID)
    );
  }

  static validateName(name) {
    return /^[A-Za-z\s-]+$/.test(name);
  }

  static validateUID(uid) {
    return /^\d{9}$/.test(uid);
  }

  static validateEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  static validateDirectoryID(directoryID) {
    return /^[0-9A-Za-z]{0,9}$/.test(directoryID);
  }

  static validateSwipeNum(swipeNum) {
    return /^[0-9]{15,18}$/.test(swipeNum);
  }

  /* Standardized functions to constrain textfields during input */

  static constrainNameInput(name) {
    return name.length <= 25 && name.match(/^[A-Za-z\s-]*$/);
  }
}

export default Validators;
